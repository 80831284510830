import { Tag } from "@components/library";
import { FONTS, WINDOW_DIMENSIONS } from "@constants";
import { PARTNERSHIP_MODEL_LABELS } from "@tsTypes/rfps";
import { FundingBenefit, NonMonetaryBenefit } from "@tsTypes/schema";
import styled from "styled-components";
import BenefitCard from "./BenefitCard";

interface Props {
  partnershipModels: string[];
  fundingBenefits: FundingBenefit[];
  nonMonetaryBenefits: NonMonetaryBenefit[];
  isResponsive?: boolean;
  withMarginTop?: boolean;
}

export default function Benefits({
  partnershipModels,
  fundingBenefits,
  nonMonetaryBenefits,
  isResponsive = true,
  withMarginTop = true,
}: Props) {
  if (partnershipModels.length + fundingBenefits.length + nonMonetaryBenefits.length === 0)
    return null;

  return (
    <WhatWeCanOfferYou withMarginTop={withMarginTop}>
      <Heading id="offer">What we can offer you</Heading>
      {Number(partnershipModels.length) > 0 && (
        <BenefitSection>
          <Subheading>Eligible partnership models:</Subheading>
          <PartnershipModels data-testid="rfp-partnership-models">
            {partnershipModels.map((partnershipModel) => (
              <Tag
                key={partnershipModel}
                content={PARTNERSHIP_MODEL_LABELS[partnershipModel]}
                size="md"
              />
            ))}
          </PartnershipModels>
        </BenefitSection>
      )}
      {(fundingBenefits?.length > 0 || nonMonetaryBenefits?.length > 0) && (
        <BenefitSection>
          <Subheading>Benefits:</Subheading>
          <BenefitsContainer isResponsive={isResponsive} data-testid="rfp-benefits">
            {fundingBenefits.map((benefit) => (
              <BenefitCard key={`${benefit.benefit_type}-${benefit.details}`} benefit={benefit} />
            ))}
            {nonMonetaryBenefits.map((benefit) => (
              <BenefitCard key={`${benefit.benefit_type}-${benefit.details}`} benefit={benefit} />
            ))}
          </BenefitsContainer>
        </BenefitSection>
      )}
    </WhatWeCanOfferYou>
  );
}

const WhatWeCanOfferYou = styled.div`
  width: calc(100% + 108px);
  ${({ withMarginTop }) => withMarginTop && `margin-top: 64px;`}

  ${WINDOW_DIMENSIONS.DESKTOP_MEDIA_QUERY} {
    width: 100%;
  }
`;
const BenefitSection = styled.div`
  display: flex;
  flex-direction: column;
`;
const PartnershipModels = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin: 16px 0 24px;
`;
const BenefitsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-top: 16px;
  ${({ isResponsive }) =>
    isResponsive &&
    `
      ${WINDOW_DIMENSIONS.DESKTOP_MEDIA_QUERY} {
        grid-template-columns: 1fr;
      }
  `}
`;
const Heading = styled.div`
  ${FONTS.HEADING_4_SEMIBOLD};
  margin-bottom: 24px;
  &:not(:first-of-type) {
    margin-top: 64px;
  }
`;
const Subheading = styled.div`
  display: flex;
  align-items: center;
  ${FONTS.SEMIBOLD_1};
  margin: 24px 0 8px;
`;
