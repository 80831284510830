import Step3CompanyScientist from "./CompanyScientist";
import Step3CompanySponsor from "./CompanySponsor";
import Step3ResearchInstitutionAdmin from "./ResearchInstitutionAdmin";
import Step3ResearchInstitutionScientist from "./ResearchInstitutionScientist";

import { Button, TextInput } from "@components/library";
import { COLORS, FEATURE_FLAGS, WINDOW_DIMENSIONS } from "@constants";
import { t } from "@utils/i18n";
import { isValidURL } from "@utils/textUtils";
import styled from "styled-components";
import { StepInfo, StepTitle } from "../Step3aGoogleScholar/Step3aGoogleScholar";
import * as S from "../StepStyles";

export const COMPANY_SPONSOR_TITLE_LIMIT = 50;

const Step3 = ({
  userType,
  handleOnboard,
  onBack,
  profileInfo,
  setProfileInfo,
  user,
  isSubmitting,
  selectedGoogleScholarAuthor,
}) => {
  const {
    company,
    role,
    title,
    companyName,
    companyURL,
    country_code,
    disciplines,
    areasOfExpertise,
    university,
    universityExpertise,
    universityHasCountry,
    howDidYouHearAboutUs,
  } = profileInfo;

  let disabled = isSubmitting;
  if (userType === "company-sponsor")
    disabled ||= !(company && role && title && title.length <= COMPANY_SPONSOR_TITLE_LIMIT);
  if (userType === "company-scientist")
    disabled ||= !(
      companyName &&
      isValidURL(companyURL) &&
      country_code &&
      role &&
      disciplines?.length &&
      areasOfExpertise?.length
    );
  if (userType === "research-administrator")
    disabled ||= !(
      university &&
      role &&
      (FEATURE_FLAGS.UNIVERSITY_ADMIN_DISCIPLINES ? disciplines?.length : universityExpertise) &&
      (universityHasCountry || country_code)
    );
  if (userType === "research-scientist")
    disabled ||= !(
      university &&
      role &&
      disciplines?.length &&
      areasOfExpertise?.length &&
      (universityHasCountry || country_code)
    );

  const onContinue = () => {
    if (userType === "company-sponsor") return handleOnboard("sponsor");
    if (userType === "research-administrator") return handleOnboard("admin");
    return handleOnboard("scientist");
  };

  return (
    <Container center={!selectedGoogleScholarAuthor}>
      {selectedGoogleScholarAuthor ? (
        <>
          <StepTitle>Confirm primary profile information</StepTitle>
          <StepInfo>
            Please review the information we&apos;ve gathered. You can import additional details
            after signup.
          </StepInfo>
        </>
      ) : (
        <S.StepTitle>Work Background</S.StepTitle>
      )}
      <S.StepContainer>
        {userType === "company-sponsor" && (
          <Step3CompanySponsor
            profileInfo={profileInfo}
            setProfileInfo={setProfileInfo}
            email={user.email}
          />
        )}
        {userType === "company-scientist" && (
          <Step3CompanyScientist
            profileInfo={profileInfo}
            setProfileInfo={setProfileInfo}
            selectedGoogleScholarAuthor={selectedGoogleScholarAuthor}
          />
        )}
        {userType === "research-administrator" && (
          <Step3ResearchInstitutionAdmin
            profileInfo={profileInfo}
            setProfileInfo={setProfileInfo}
          />
        )}
        {userType === "research-scientist" && (
          <Step3ResearchInstitutionScientist
            profileInfo={profileInfo}
            setProfileInfo={setProfileInfo}
            selectedGoogleScholarAuthor={selectedGoogleScholarAuthor}
          />
        )}
        {FEATURE_FLAGS.HOW_DID_YOU_HEAR_ABOUT_US && (
          <S.DropdownRow>
            <S.DropdownTitle>
              {t("pages.onboarding_container.how_did_you_hear_about_us.label")}
            </S.DropdownTitle>
            <S.DropdownSubtitle>Optional</S.DropdownSubtitle>
            <TextInput
              placeholder={t("pages.onboarding_container.how_did_you_hear_about_us.placeholder")}
              placeholderColor={COLORS.NEUTRAL_400}
              value={howDidYouHearAboutUs}
              onChange={(e) => {
                setProfileInfo({ ...profileInfo, howDidYouHearAboutUs: e.target.value });
              }}
              hideClearButton
            />
          </S.DropdownRow>
        )}
        <S.ButtonContainer>
          <Button type="button" variant="ghost" width="120px" onClick={() => onBack("step2")}>
            Back
          </Button>
          <Button
            type="button"
            disabled={disabled}
            onClick={onContinue}
            data-testid="company-sponsor-step-4-submit-button"
            width="120px"
          >
            Submit
          </Button>
        </S.ButtonContainer>
      </S.StepContainer>
    </Container>
  );
};

export default Step3;
const Container = styled.div`
  text-align: left;
  padding: 0;
  margin-bottom: 200px;
  ${({ center }) => !center && `width: 800px;`}

  ${WINDOW_DIMENSIONS.TABLET_MEDIA_QUERY} {
    padding: 0 30px;
    width: 100%;
  }
`;
