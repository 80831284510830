import { AnyTypeUser } from "@requests/users";
import { ReviewTeamMember, ReviewerDashboardRequest, RfpPageRequest } from "@tsTypes/index";
import {
  LaunchableRequestStatus,
  RequestFeedbackDeadlineStatus,
  RequestableType,
} from "@tsTypes/requests";
import { RoleName } from "@tsTypes/roles";
import { Request } from "@tsTypes/schema";
import { SponsorLicenseType } from "@tsTypes/sponsorProfiles";
import { UserRole } from "@tsTypes/users";
import { differenceInDays, isAfter, isBefore } from "date-fns";
import { ACCESS_CODE_PARAM } from "src/views/RFPPage/RfpPage";
import { sortByHasProfilePicture } from "./userUtils";

type BasicRequest = Pick<Request, "id" | "requestable_type"> & {
  complete?: boolean;
  company: {
    id?: number;
  };
};

export const findRequestRoleNameOnUser = <
  User extends Pick<AnyTypeUser, "first_name" | "role" | "roles" | "profile_info">,
  Request extends BasicRequest
>(
  user: User,
  request: Request
): RoleName | null => {
  const isPremium = user.profile_info.license === SponsorLicenseType.PREMIUM;
  const isAdmin = user.profile_info.is_admin;
  const isSuperAdmin = user.profile_info.is_super_admin;
  const isDraft = request.complete !== undefined && !request.complete;
  const isOpenCall = request.requestable_type === RequestableType.OPEN_CALL;

  if (isAdmin && (isPremium || isDraft || isOpenCall)) {
    if (isSuperAdmin) {
      return RoleName.COMPANY_SUPER_ADMIN;
    }
    return RoleName.COMPANY_ADMIN;
  }

  return (
    user.roles?.find(
      (role) =>
        role.resource_type === "Request" &&
        role.resource_id === request.id &&
        isDraft === (role.name === RoleName.REQUEST_DRAFT_EDITOR)
    )?.name ?? null
  );
};

export const canAccessRequest = <Request extends BasicRequest>(
  user?: AnyTypeUser | null,
  request?: Request | null
): boolean => {
  if (!user || !request) return false;

  const isSponsor = user.role === UserRole.SPONSOR;
  const isPremium = user.profile_info.license === SponsorLicenseType.PREMIUM;
  const isAtCompany = request.company.id === user.profile_info.company_id;
  const isAdmin = isAtCompany && user.profile_info.is_admin;
  const isDraft = request.complete !== undefined && !request.complete;
  const isOpenCall = request.requestable_type === RequestableType.OPEN_CALL;

  // User must be on review team and have a role that matches the request's current state
  return (
    // Premium admins can access all requests as leads
    (isSponsor && isAdmin && isPremium) ||
    // All admins can access open calls and drafts as leads
    (isSponsor && isAdmin && (isDraft || isOpenCall)) ||
    Boolean(
      user.roles?.some(
        (role) =>
          role.resource_type === "Request" &&
          role.resource_id === request.id &&
          (isDraft
            ? role.name === RoleName.REQUEST_DRAFT_EDITOR
            : role.name === RoleName.REQUEST_LEAD ||
              role.name === RoleName.REQUEST_COMMENTER ||
              role.name === RoleName.REQUEST_EXPERT)
      )
    )
  );
};

export const canAccessRequestAsLead = <Request extends BasicRequest>(
  user: AnyTypeUser,
  request: Request
): boolean => {
  if (
    !user ||
    user.role !== UserRole.SPONSOR ||
    !request ||
    request.company.id !== user.profile_info.company_id
  )
    return false;

  const isPremium = user.profile_info.license === SponsorLicenseType.PREMIUM;
  const isAdmin = user.profile_info.is_admin;
  const isDraft = request.complete !== undefined && !request.complete;
  const isOpenCall = request.requestable_type === RequestableType.OPEN_CALL;

  // User must be on review team and have an /elevated/ role that matches the request's current state
  return (
    // Premium admins can access all requests as leads
    (isPremium && isAdmin) ||
    // All admins can access open calls and drafts as leads
    (isAdmin && (isDraft || isOpenCall)) ||
    Boolean(
      user.roles?.some(
        (role) =>
          role.resource_type === "Request" &&
          role.resource_id === request.id &&
          (isDraft
            ? role.name === RoleName.REQUEST_DRAFT_EDITOR
            : role.name === RoleName.REQUEST_LEAD)
      )
    )
  );
};

export const filterReviewTeamByRole = ({
  requestId,
  team,
  roleName,
}: {
  requestId: number;
  team: ReviewTeamMember[];
  roleName: RoleName;
}): ReviewTeamMember[] =>
  team.filter((member) =>
    member.roles.find(
      (role) =>
        role.resource_type === "Request" && role.resource_id === requestId && role.name === roleName
    )
  );

export const reviewTeamMembersForRfpPage = (request: RfpPageRequest): ReviewTeamMember[] => {
  const reviewTeamMembers = request.review_team_members;
  if (!reviewTeamMembers) return [];
  const leads = sortByHasProfilePicture(
    reviewTeamMembers?.filter((reviewer) => canAccessRequestAsLead(reviewer as any, request))
  );
  return leads;
};

export const reviewTeamMembersForReviewerDashboardThumbnails = (
  request: ReviewerDashboardRequest
) => {
  const reviewTeamMembers = request.review_team_members;
  const superAdminReviewers = reviewTeamMembers.filter(
    (reviewer) => reviewer.profile_info.is_super_admin
  );
  const onlyAdminReviewers = reviewTeamMembers.filter(
    (reviewer) => reviewer.profile_info.is_admin && !reviewer.profile_info.is_super_admin
  );
  const leads = sortByHasProfilePicture(
    filterReviewTeamByRole({
      requestId: request.id,
      team: reviewTeamMembers,
      roleName: RoleName.REQUEST_LEAD,
    })
  );
  const commenters = sortByHasProfilePicture(
    filterReviewTeamByRole({
      requestId: request.id,
      team: reviewTeamMembers,
      roleName: RoleName.REQUEST_COMMENTER,
    })
  );
  const draftEditors = sortByHasProfilePicture(
    filterReviewTeamByRole({
      requestId: request.id,
      team: reviewTeamMembers,
      roleName: RoleName.REQUEST_DRAFT_EDITOR,
    })
  );

  return [
    ...(request.complete ? [...leads, ...commenters] : draftEditors),
    ...onlyAdminReviewers,
    ...superAdminReviewers,
  ].slice(0, 3);
};

export const requestPageUrlPath = (
  request?: Record<string, any>,
  hideAccessCode = false
): string => {
  switch (request?.requestable_type) {
    case RequestableType.OPEN_CALL: {
      return `/company/${request.company.identifier}`;
    }
    case RequestableType.RFP: {
      return (
        `/research/${request.requestable?.rfp_program_identifier || "program"}/${request.slug}` +
        (request.access_code && !hideAccessCode
          ? `?${ACCESS_CODE_PARAM}=${request.access_code}`
          : "")
      );
    }
    case RequestableType.REQUEST_FOR_STARTUP: {
      return `/startup_program/${request.slug}`;
    }
    default: {
      throw new Error(`Invalid requestable type: ${request?.requestable_type}`);
    }
  }
};

export const requestProposalUrlPath = (request?: any): string => {
  switch (request?.requestable_type) {
    case RequestableType.OPEN_CALL: {
      return `/submit/open_call/${request.slug}`;
    }
    case RequestableType.RFP: {
      return (
        `/submit/request_for_proposal/${request.slug}` +
        (request.access_code ? `?${ACCESS_CODE_PARAM}=${request.access_code}` : "")
      );
    }
    case RequestableType.REQUEST_FOR_STARTUP: {
      return `/submit/request_for_startup/${request.slug}`;
    }
    default: {
      throw new Error(`Invalid requestable type: ${request?.requestable_type}`);
    }
  }
};

export type LaunchableRequest = Pick<Request, "enabled" | "feedback_deadline"> & {
  requestable: {
    launch_date: string | null;
    deadline: string | null;
  };
};

export const getLaunchableRequestStatus = <Request extends LaunchableRequest>(
  request: Request
): LaunchableRequestStatus => {
  if (!request.requestable.launch_date && !request.requestable.deadline) {
    if (request.enabled) return LaunchableRequestStatus.ACTIVE;
    return LaunchableRequestStatus.CLOSED;
  }

  const isBeforeLaunchDate =
    Boolean(request.requestable.launch_date) &&
    isBefore(new Date(), new Date(request.requestable.launch_date ?? 0));
  const isPastDeadline =
    Boolean(request.requestable.deadline) &&
    isAfter(new Date(), new Date(request.requestable.deadline ?? 0));

  if (isBeforeLaunchDate) return LaunchableRequestStatus.COMING_SOON;
  if (request.enabled)
    if (isPastDeadline) return LaunchableRequestStatus.IN_EXTENSION;
    else return LaunchableRequestStatus.ACTIVE;
  return LaunchableRequestStatus.CLOSED;
};

export const canUseFeedbackDeadline = (request: {
  created_at?: string;
  requestable?: {
    launch_date: string | null;
    deadline: string | null;
  } | null;
}): boolean => {
  const FEEDBACK_DEADLINE_CUTOFF_DATE = new Date("8-17-2023");

  return (
    new Date(request.created_at ?? 0) >= FEEDBACK_DEADLINE_CUTOFF_DATE ||
    new Date(request.requestable?.launch_date ?? 0) >= FEEDBACK_DEADLINE_CUTOFF_DATE ||
    new Date(request.requestable?.deadline ?? 0) >= FEEDBACK_DEADLINE_CUTOFF_DATE
  );
};
export const getRequestFeedbackDeadlineStatus = (request: {
  feedback_deadline: string | null;
  created_at?: string;
  requestable?: {
    launch_date: string | null;
    deadline: string | null;
  } | null;
}): RequestFeedbackDeadlineStatus => {
  if (
    !canUseFeedbackDeadline(request) ||
    !request.requestable?.deadline ||
    new Date() < new Date(request.requestable?.deadline)
  ) {
    return RequestFeedbackDeadlineStatus.NOT_APPLICABLE;
  }

  if (!request.feedback_deadline) return RequestFeedbackDeadlineStatus.NEEDS_DEADLINE;

  const daysUntilDeadline = differenceInDays(new Date(request.feedback_deadline), new Date());

  if (daysUntilDeadline < 0) return RequestFeedbackDeadlineStatus.PAST_DEADLINE;
  if (daysUntilDeadline < 5) return RequestFeedbackDeadlineStatus.DEADLINE_APPROACHING;
  return RequestFeedbackDeadlineStatus.BEFORE_DEADLINE;
};
