import { Icon } from "@components/library";
import { COLORS, FONTS } from "@constants";
import { RfpBenefitType } from "@tsTypes/rfps";
import { FundingBenefit, NonMonetaryBenefit } from "@tsTypes/schema";
import { ReactNode } from "react";
import styled from "styled-components";

interface Props {
  benefit: FundingBenefit | NonMonetaryBenefit;
}

const BenefitCard = ({ benefit: { benefit_type, details } }: Props) => {
  let title: ReactNode = "";

  switch (benefit_type) {
    case RfpBenefitType.SPONSORED_RESEARCH:
      title = (
        <Title>
          <Icon name="Funding" margin="0 6px 0 0" />
          Sponsored Research
        </Title>
      );
      break;
    case RfpBenefitType.GIFT:
      title = (
        <Title>
          <Icon name="Funding" margin="0 6px 0 0" />
          Gift (Unrestricted grant)
        </Title>
      );
      break;
    case RfpBenefitType.INVESTMENT:
      title = (
        <Title>
          <Icon name="Funding" margin="0 6px 0 0" />
          Investment
        </Title>
      );
      break;
    case RfpBenefitType.EXPERTISE:
      title = (
        <Title>
          <Icon name="Expertise" margin="0 6px 0 0" />
          Expertise
        </Title>
      );
      break;
    case RfpBenefitType.NETWORKING:
      title = (
        <Title>
          <Icon name="Public" margin="0 6px 0 0" />
          Networking
        </Title>
      );
      break;
    case RfpBenefitType.TOOLS_AND_TECHNOLOGY:
      title = (
        <Title>
          <Icon name="Technology" margin="0 6px 0 0" />
          Tools and Technologies
        </Title>
      );
      break;
    case RfpBenefitType.COMPOUNDS_AND_REAGENTS:
      title = (
        <Title>
          <Icon name="Compounds" margin="0 6px 0 0" />
          Compounds and Reagents
        </Title>
      );
      break;
    case RfpBenefitType.DATA:
      title = (
        <Title>
          <Icon name="Data" margin="0 6px 0 0" />
          Data
        </Title>
      );
      break;
    case RfpBenefitType.FACILITIES_AND_SERVICES:
      title = (
        <Title>
          <Icon name="Organization" margin="0 6px 0 0" />
          Facilities and Services
        </Title>
      );
      break;
    case RfpBenefitType.SERVICES_AGREEMENT:
      title = (
        <Title>
          <Icon name="Funding" margin="0 6px 0 0" />
          Services Agreement
        </Title>
      );
      break;
    default:
      title = "";
  }

  return (
    <Container>
      {title}
      {details}
    </Container>
  );
};

export default BenefitCard;

const Container = styled.div`
  border: 1px solid ${COLORS.NEUTRAL_300};
  background-color: ${COLORS.WHITE};
  color: ${COLORS.BLACK};
  border-radius: 4px;
  padding: 16px;
  ${FONTS.REGULAR_2};
  word-break: break-word;
  white-space: pre-wrap;
`;
const Title = styled.div`
  display: flex;
  align-items: center;
  ${FONTS.SEMIBOLD_1};
  margin-bottom: 16px;
  color: ${COLORS.BLACK};
`;
