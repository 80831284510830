import { InfoBanner, SingleSelectDropdown, TextInput } from "@components/library";
import KeywordsDropdown from "@components/library/Dropdowns/KeywordsDropdown/KeywordsDropdown";
import { COUNTRY_OPTIONS, FEATURE_FLAGS } from "@constants";
import { getOptionFromValue, getValueFromOption } from "@utils/dropdownUtils";
import { isValidURL } from "@utils/textUtils";
import { useEffect } from "react";
import * as S from "../StepStyles";

export const STARTUP_TITLES = ["Business Development", "CEO", "CSO", "Researcher"];
export const STARTUP_TITLE_OPTIONS = STARTUP_TITLES.map(getOptionFromValue);
const companyRoleOptions = STARTUP_TITLES.map((text) => ({
  label: text,
  value: text,
}));

function CompanyScientist({ profileInfo, setProfileInfo, selectedGoogleScholarAuthor }) {
  const { countryOption, role, disciplines, areasOfExpertise } = profileInfo;

  const handleCompany = (e) => {
    setProfileInfo((oldProfileInfo) => ({
      ...oldProfileInfo,
      companyName: e.target.value,
    }));
  };

  const handleCompanyWebsite = (e) => {
    setProfileInfo((oldProfileInfo) => ({
      ...oldProfileInfo,
      companyURL: e.target.value,
    }));
  };

  // Prefill data if author is selected
  useEffect(() => {
    if (!selectedGoogleScholarAuthor) return;
    setProfileInfo((oldProfileInfo) => ({
      ...oldProfileInfo,
      disciplines: selectedGoogleScholarAuthor.disciplines,
      areasOfExpertise: selectedGoogleScholarAuthor.areas_of_expertise,
    }));
    // step2: startup, step3: startup (match!)
    if (!selectedGoogleScholarAuthor.is_university_scientist) {
      // prefill company name, role
      handleCompany({ target: { value: selectedGoogleScholarAuthor.location } });
      setProfileInfo((oldProfileInfo) => ({
        ...oldProfileInfo,
        role: selectedGoogleScholarAuthor.title,
      }));
    }
  }, []);

  return (
    <>
      <InfoBanner
        shouldDisplay={Boolean(
          FEATURE_FLAGS.GOOGLE_SCHOLAR_ONBOARDING_STEP &&
            selectedGoogleScholarAuthor?.is_university_scientist
        )}
        text={
          <>
            Google Scholar shows a research institution as your current organization, but
            you&apos;ve indicated you&apos;re currently at a company. Please add your details below.
          </>
        }
        type="ghost"
        margin="0 0 32px"
        padding="0"
      />
      <S.DropdownRow>
        <S.DropdownTitle>Company Name</S.DropdownTitle>
        <TextInput
          placeholder="Company name"
          onChange={handleCompany}
          value={profileInfo?.companyName || ""}
          data-testid="company-scientist-company-name-input"
        />
      </S.DropdownRow>
      <S.DropdownRow>
        <S.DropdownTitle>Company Website</S.DropdownTitle>
        <TextInput
          placeholder="Company Website"
          onChange={handleCompanyWebsite}
          value={profileInfo?.companyURL || ""}
          data-testid="company-scientist-company-website-input"
          errors={[
            {
              hasError:
                Boolean(profileInfo?.companyURL?.length) && !isValidURL(profileInfo?.companyURL),
              errorMessage: "Please enter a valid URL",
            },
          ]}
        />
      </S.DropdownRow>
      <S.DropdownRow>
        <S.DropdownTitle>Where is your company located?</S.DropdownTitle>
        <SingleSelectDropdown
          placeholder="Search countries"
          value={countryOption}
          options={COUNTRY_OPTIONS}
          onChange={(option) =>
            setProfileInfo((oldProfileInfo) => ({
              ...oldProfileInfo,
              countryOption: option,
              country_code: getValueFromOption(option),
            }))
          }
          data-testid="company-scientist-country-dropdown"
        />
      </S.DropdownRow>
      <S.DropdownRow>
        <S.DropdownTitle>Which of the following best describes your role?</S.DropdownTitle>
        <SingleSelectDropdown
          placeholder="Select your role"
          options={companyRoleOptions}
          onChange={(e) =>
            setProfileInfo((oldProfileInfo) => ({
              ...oldProfileInfo,
              role: getValueFromOption(e),
            }))
          }
          value={getOptionFromValue(role)}
          data-testid="company-scientist-role-dropdown"
        />
      </S.DropdownRow>
      <S.Hairline />
      <S.DropdownRow>
        <S.DropdownTitle>
          Which academic departments or disciplines are most relevant to your work?
        </S.DropdownTitle>
        <S.DropdownSubtitle>You can add up to 3.</S.DropdownSubtitle>
        <KeywordsDropdown
          type="disciplines"
          value={disciplines ?? []}
          onChange={(selection) => setProfileInfo({ ...profileInfo, disciplines: selection })}
          maxValues={3}
          placeholder="Add department or discipline"
          showSuggestions
        />
      </S.DropdownRow>
      <S.DropdownRow>
        <S.DropdownTitle>What are your areas of expertise?</S.DropdownTitle>
        <S.DropdownSubtitle>You can add up to 5.</S.DropdownSubtitle>
        <KeywordsDropdown
          type="areas_of_expertise"
          value={areasOfExpertise ?? []}
          onChange={(selection) => setProfileInfo({ ...profileInfo, areasOfExpertise: selection })}
          maxValues={5}
          placeholder="Add areas of expertise"
          showSuggestions
        />
      </S.DropdownRow>
    </>
  );
}

export default CompanyScientist;
