import { Button, Icon } from "@components/library";
import { COLORS } from "@constants";
import { marketplacePath } from "@routes/utils";
import { getPrevUrlCookie } from "@utils/authUtils";
import {
  NextStepDescription,
  NextStepHeader,
  NextStepsContainer,
  StepContainer,
  StepDescription,
  StepTitle,
} from "../StepStyles";

export default function Step4Scientist() {
  const routeToMarketplace = () => {
    getPrevUrlCookie(marketplacePath("requests", { follow: "true" }), false, 0);
  };

  const previousAction = docCookies.getItem("prevUrl") !== null;
  const isWhiteLabel = location.pathname.startsWith("/partner");

  if (isWhiteLabel) {
    return (
      <StepContainer center>
        <StepTitle large center>
          <Icon name="Success" color={COLORS.GREEN} size="xl" />
          You&rsquo;re all set!
        </StepTitle>
        <StepDescription marginBottom="24">
          Now go ahead and get started with your first proposal
        </StepDescription>
        <Button
          type="button"
          disabled={false}
          onClick={() => routeToMarketplace()}
          data-testid="completed-scientist-onboarding-button"
        >
          Get started
        </Button>
      </StepContainer>
    );
  }

  return (
    <StepContainer>
      <StepTitle>Registration complete!</StepTitle>
      <StepDescription marginBottom="60">
        Thank you for creating a Halo account! Here&rsquo;s how you can make the most out of your
        time with us.
      </StepDescription>
      <NextStepsContainer>
        <Icon name="Profile" size="xl" color={COLORS.BLUE_450} margin="0 10px 0 0" />
        <div>
          <NextStepHeader>Set up your scientist profile</NextStepHeader>
          <NextStepDescription>
            Fill out your profile with your research experience, expertise, and publications
          </NextStepDescription>
        </div>
      </NextStepsContainer>
      <NextStepsContainer>
        <Icon name="Search" size="xl" color={COLORS.BLUE_450} margin="0 10px 0 0" />
        <div>
          <NextStepHeader>Browse industry opportunities</NextStepHeader>
          <NextStepDescription>
            Use our marketplace to find opportunities to collaborate with companies.
          </NextStepDescription>
        </div>
      </NextStepsContainer>
      <NextStepsContainer>
        <Icon name="Send Message" size="xl" color={COLORS.BLUE_450} margin="0 10px 0 0" />
        <div>
          <NextStepHeader>Submit proposals</NextStepHeader>
          <NextStepDescription>
            Apply to opportunities and receive feedback from the company.
          </NextStepDescription>
        </div>
      </NextStepsContainer>
      <Button
        type="button"
        disabled={false}
        onClick={() => routeToMarketplace()}
        data-testid="completed-scientist-onboarding-button"
      >
        {previousAction ? "Finish" : "Go to Halo"}
      </Button>
    </StepContainer>
  );
}
