import { Button } from "@components/library";
import { getPrevUrlCookie } from "@utils/authUtils";
import * as S from "../StepStyles";

export default function Step4Administrator() {
  const routeToUniversityDashboard = () => {
    getPrevUrlCookie("/university_dashboard/team", true, 2);
  };

  const previousAction = docCookies.getItem("prevUrl") !== null;

  return (
    <S.StepContainer>
      <S.StepTitle>Registration complete!</S.StepTitle>
      <S.DropdownRow>
        <div>
          Now you can see who else at your institution has joined Halo, invite faculty to submit
          proposals and track their status.
        </div>
      </S.DropdownRow>
      <Button
        type="button"
        disabled={false}
        onClick={() => routeToUniversityDashboard()}
        data-testid="completed-admin-onboarding-button"
      >
        {previousAction ? "Finish" : "Go to dashboard"}
      </Button>
    </S.StepContainer>
  );
}
