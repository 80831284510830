import { Button, Icon } from "@components/library";
import { COLORS, FONTS, SUBSCRIPTION_END_WARNING_START, WINDOW_DIMENSIONS } from "@constants";
import { RootState } from "@redux/store";
import { daysUntilLocalDate } from "@utils/appUtils";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import SubscriptionEndingModal from "./SubscriptionEndingModal";

const { TABLET_MEDIA_QUERY } = WINDOW_DIMENSIONS;

const SubscriptionWarning = () => {
  const currentUser = useSelector((state: RootState) => state.profiles.currentUser);
  const currentCompany = useSelector((state: RootState) => state.defaultReducer.currentCompany);
  const subscription = useSelector((state: RootState) => state.company.subscription);

  const { role } = currentUser;
  const subscription_end_date = subscription?.end_at ?? currentCompany.last_subscription_end_date;
  const isSuperAdmin = currentUser?.profile_info?.is_super_admin;

  const daysUntilSubscriptionEnds = daysUntilLocalDate(subscription_end_date);
  const [subscriptionEndingModalOpen, setSubscriptionEndingModalOpen] = useState(false);

  useEffect(() => {
    setSubscriptionEndingModalOpen(daysUntilSubscriptionEnds! < 0);
  }, [currentCompany]);

  if (!daysUntilSubscriptionEnds || (!isSuperAdmin && daysUntilSubscriptionEnds >= 0)) return null;

  let subscriptionBannerText = `Only ${daysUntilSubscriptionEnds} day${
    daysUntilSubscriptionEnds === 1 ? "" : "s"
  } left on your Halo subscription.`;
  if (daysUntilSubscriptionEnds === 0) {
    subscriptionBannerText = "Your subscription expires today.";
  } else if (daysUntilSubscriptionEnds < 0) {
    subscriptionBannerText = "Your subscription has expired.";
  }

  return (
    <>
      {subscriptionEndingModalOpen && (
        <SubscriptionEndingModal
          isOpen={subscriptionEndingModalOpen}
          closeModal={() => setSubscriptionEndingModalOpen(false)}
          daysRemaining={daysUntilSubscriptionEnds}
        />
      )}
      {daysUntilSubscriptionEnds <= SUBSCRIPTION_END_WARNING_START && role === 1 && (
        <SubscriptionEndBanner>
          <Icon name="Attention" size="lg" color={COLORS.WHITE} margin="0 5px 0 0" />
          {subscriptionBannerText} Renew to continue accessing all Halo features.
          <Link
            to={isSuperAdmin ? "/contact" : `mailto:${currentCompany?.super_admin?.email}`}
            target="_blank"
            style={{ backgroundColor: COLORS.WHITE }}
          >
            <Button type="button" variant="destructive-ghost" size="sm">
              {isSuperAdmin ? "Contact Us" : "Contact your Admin"}
            </Button>
          </Link>
        </SubscriptionEndBanner>
      )}
    </>
  );
};

const SubscriptionEndBanner = styled.div`
  display: flex;
  align-items: center;
  height: 60px;
  width: 100%;
  margin: 0px;
  padding: 0 ${WINDOW_DIMENSIONS.PAGE_SIDE_PADDING}px;
  background-color: ${COLORS.RED};
  color: ${COLORS.WHITE};
  ${FONTS.REGULAR_2};

  & > a {
    margin-left: auto;
  }

  ${TABLET_MEDIA_QUERY} {
    width: 100%;
    height: 80px;
    left: 0;
    padding: 0 18px;
  }
`;

export default SubscriptionWarning;
