import {
  ChangeEvent,
  Dispatch,
  ReactNode,
  SetStateAction,
  SyntheticEvent,
  useEffect,
  useState,
} from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { SSOEnabledHint } from "@components/SSOEnabledHint";
import { GradientButton, Icon } from "@components/library";
import { COLORS, FONTS, STYLES, WINDOW_DIMENSIONS } from "@constants";
import { useSSOSensitiveEmail } from "@hooks/useSSOSensitiveEmail";
import haloLogo from "@images/logos/halo_logo.svg";
import { signIn } from "@requests/users";
import { getPrevUrlCookie, setJustLoggedIn } from "@utils/authUtils";
import { t } from "@utils/i18n";
import { ModalType } from "./LoginModal";

interface Props {
  header?: ReactNode;
  title?: ReactNode;
  bottom?: ReactNode;
  setModalType?: Dispatch<SetStateAction<ModalType>>;
  // Flag for changing styles to be compatible with the old login modal
  // @components/LoginModal/LoginModal.tsx
  compat?: boolean;
}

const Login = ({ header, title, bottom, setModalType, compat = false }: Props) => {
  const [password, setPassword] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [error, setError] = useState("");

  const { email, setEmail, isEmailSSOEnabled, redirectToSSO } = useSSOSensitiveEmail("");
  const [shouldShowSSO, setShouldShowSSO] = useState(isEmailSSOEnabled);

  useEffect(() => {
    if (isEmailSSOEnabled) setShouldShowSSO(true);
  }, [isEmailSSOEnabled]);

  const handleLogin = async (e: SyntheticEvent) => {
    e.preventDefault();

    setSubmitting(true);
    setHasSubmitted(true);
    setError("");

    if (shouldShowSSO) {
      if (!isEmailSSOEnabled) {
        setError("Your email/company is not enabled to use SSO. Please use your password instead.");
        setSubmitting(false);
        return;
      }

      redirectToSSO();
      return;
    }

    if (!email || !password) {
      setError("Please fill in all fields before continuing.");
      setSubmitting(false);
      return;
    }

    try {
      const response = await signIn({ email, password });
      setJustLoggedIn();
      getPrevUrlCookie("/redirect_login", true, response.data.role);
    } catch (err) {
      switch (err.response.status) {
        case 403:
          setError(t("error.generic_retry"));
          break;
        case 429:
          setError("You have made too many attempts. Please try again in a few minutes.");
          break;
        default:
          setError(err.response.data.error);
      }
      setSubmitting(false);
    }
  };

  return (
    <Modal compat={compat}>
      <Top compat={compat}>
        {header ?? (
          <Logo to="/">
            <img src={haloLogo} />
          </Logo>
        )}
        {title ?? (
          <Title>
            <Header>Welcome Back!</Header>
            <Subheader>Sign in to find research partners and keep moving science forward</Subheader>
          </Title>
        )}
        <Form onSubmit={handleLogin}>
          <Error data-testid="auth-error-message">{error}</Error>
          <Input
            error={(hasSubmitted && !email) || error}
            data-testid="login-form-email-input"
            name="email"
            value={email}
            type="email"
            placeholder="Email"
            onChange={(e) => {
              setHasSubmitted(false);
              setEmail(e.target.value);
            }}
          />
          {shouldShowSSO ? (
            <>
              <SSOEnabledHint />
              <GradientButton
                data-testid="auth-button"
                width="100%"
                size="md"
                disabled={submitting}
                type="submit"
              >
                Continue with SSO
              </GradientButton>
              <LoginLink
                as="div"
                onClick={() => {
                  if (isEmailSSOEnabled) setEmail("");
                  setShouldShowSSO(false);
                }}
              >
                Go back {isEmailSSOEnabled && " and use a different email"}
              </LoginLink>
            </>
          ) : (
            <>
              <Input
                error={(hasSubmitted && !password) || error}
                data-testid="login-form-password-input"
                name="password"
                value={password}
                type="password"
                placeholder="Password"
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setHasSubmitted(false);
                  setPassword(e.target.value);
                }}
              />
              <GradientButton
                data-testid="auth-button"
                width="100%"
                size="md"
                disabled={submitting}
                type="submit"
              >
                Sign in
              </GradientButton>
              <LinkContainer>
                <LoginLink as="div" onClick={() => setShouldShowSSO(true)}>
                  <Icon name="Lock" size="sm" /> Sign in with SSO
                </LoginLink>
                <LoginLink to="/forgot_password">Forgot password?</LoginLink>
              </LinkContainer>
            </>
          )}
        </Form>
      </Top>
      <Bottom compat={compat}>
        {bottom ?? (
          <>
            Don&rsquo;t have an account?&nbsp;
            {setModalType ? (
              <SignUp to="#" onClick={() => setModalType("signup")}>
                Sign up
              </SignUp>
            ) : (
              <SignUp to="/get-started">Sign up</SignUp>
            )}
          </>
        )}
      </Bottom>
    </Modal>
  );
};

export { Login };

const { TABLET_MEDIA_QUERY, MOBILE_MEDIA_QUERY } = WINDOW_DIMENSIONS;

const Modal = styled.div`
  border-radius: 12px;
  background-color: ${COLORS.WHITE};

  ${({ compat }) => !compat && `box-shadow: ${STYLES.SHADOW_E};`}

  ${TABLET_MEDIA_QUERY} {
    max-width: 95%;
    width: unset;
  }

  ${MOBILE_MEDIA_QUERY} {
    max-width: 100%;
    height: 100%;
    border-radius: 0;
  }
`;

const Logo = styled(Link)`
  text-align: center;
`;

const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

const Header = styled.div`
  ${FONTS.HEADING_3_SEMIBOLD}
  ${MOBILE_MEDIA_QUERY} {
    ${FONTS.HEADING_4_SEMIBOLD}
  }
`;

const Subheader = styled.div`
  ${FONTS.REGULAR_1}
  max-width: 372px;
  text-align: center;
  ${MOBILE_MEDIA_QUERY} {
    display: none;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin-top: -32px;
`;

const Error = styled.div`
  ${FONTS.REGULAR_3}
  margin-top: 5px;
  min-height: 20px;
  color: ${COLORS.RED};
  text-align: center;
  max-width: 300px;
`;

const Input = styled.input`
  ${FONTS.REGULAR_2}

  height: 42px;
  width: 100%;
  padding: 10px;

  border: solid 1px ${COLORS.NEUTRAL_200};
  border-radius: 6px;

  ${({ error }) => error && `border: solid 1px ${COLORS.RED};`}

  ${MOBILE_MEDIA_QUERY} {
    border-color: ${COLORS.NEUTRAL_200};
  }
`;

const Top = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 40px;
  ${({ compat }) => (compat ? `padding: 10px 10px 30px;` : `padding: 48px;`)}
  ${TABLET_MEDIA_QUERY} {
    padding: 40px 24px;
  }
`;

const Bottom = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;
  flex-direction: column;

  border-top: 1px solid ${COLORS.NEUTRAL_300};

  padding: 16px;

  text-align: center;
  display: inline-block;

  ${({ compat }) =>
    compat
      ? `
    ${FONTS.MEDIUM_2}
    padding-bottom: 4px;`
      : `
    ${FONTS.SEMIBOLD_2}
  `}

  ${MOBILE_MEDIA_QUERY} {
    margin-top: 15px;
    width: 100%;
    color: ${COLORS.NEUTRAL_500};
    ${FONTS.SEMIBOLD_1}
    text-align: left;
  }
`;

const LinkContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 8px;
`;

const LoginLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: 4px;

  text-decoration: underline;
  color: ${COLORS.BLACK};
  ${FONTS.REGULAR_2}

  ${MOBILE_MEDIA_QUERY} {
    color: ${COLORS.NEUTRAL_500};
    ${FONTS.SEMIBOLD_1}

    text-decoration: none;
  }

  &:hover {
    cursor: pointer;
    color: ${COLORS.BLUE_900};
  }
`;

const SignUp = styled(Link)`
  text-decoration: underline;
  color: ${COLORS.BLACK};
  ${FONTS.REGULAR_2}

  ${MOBILE_MEDIA_QUERY} {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 48px;
    margin-top: 12px;

    border-radius: 3px;
    border-style: solid;
    border-width: 2px;

    color: ${COLORS.HALO_BLUE};
    ${FONTS.SEMIBOLD_1}

    text-decoration: none;
  }
`;
