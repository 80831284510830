import {
  ChipsInput,
  MultiSelectDropdown,
  SingleSelectDropdown,
  UniversityDropdown,
} from "@components/library";
import { COUNTRY_OPTIONS, FEATURE_FLAGS } from "@constants";
import { getDisciplines } from "@requests/keywords";
import { useQuery } from "@tanstack/react-query";
import { UniversityAdminRoles } from "@tsTypes/universityProfiles";
import { getOptionFromValue, getValueFromOption } from "@utils/dropdownUtils";
import { useEffect } from "react";
import api from "src/requests/request";
import * as S from "../StepStyles";

const userRoleOptions = Object.values(UniversityAdminRoles).map((text) => ({
  label: text,
  value: text,
}));

const expertiseOptions = [
  "Healthcare and Life Sciences",
  "Engineering and Physical Sciences",
  "New Venture Creation",
  "Data Science",
  "Agriculture",
].map((text) => ({
  label: text,
  value: text,
}));

function ResearchInstitutionAdmin({ profileInfo, setProfileInfo, prefillInstitutionName = "" }) {
  const { data: disciplineOptions } = useQuery({
    queryKey: ["getDisciplines"],
    queryFn: () => getDisciplines(),
    select: (data) => data.map(getOptionFromValue),
  });

  useEffect(() => {
    if (!disciplineOptions) return;
    if ("disciplines" in profileInfo) return;
    setProfileInfo({
      ...profileInfo,
      disciplines: disciplineOptions?.map(getValueFromOption),
    });
  }, [disciplineOptions]);

  const {
    university,
    universityHasCountry,
    role,
    universityExpertise,
    countryOption,
    disciplines,
  } = profileInfo;

  const handleDisciplines = (values) => {
    setProfileInfo({
      ...profileInfo,
      disciplines: values,
    });
  };

  const handleExpertise = (e, selection) => {
    if (selection?.action === "select-option" && e.length <= 3) {
      setProfileInfo({
        ...profileInfo,
        universityExpertise: e.map((v) => v.value) || [],
      });
    }
  };

  const handleSelectUniversityOption = async (option) => {
    if (!option) return;

    const responseJson = await api.get("/universities/has_country", {
      params: { name: getValueFromOption(option) },
    });

    const { has_country } = responseJson.data;

    setProfileInfo({
      ...profileInfo,
      university: getValueFromOption(option),
      universityHasCountry: has_country,
    });
  };

  return (
    <>
      <S.DropdownRow>
        <S.DropdownTitle>Research Institution</S.DropdownTitle>
        {prefillInstitutionName.length > 0 ? (
          <S.Input type="text" value={prefillInstitutionName} tabIndex={-1} fixed readOnly />
        ) : (
          <>
            <UniversityDropdown
              value={getOptionFromValue(university)}
              onChange={handleSelectUniversityOption}
              data-testid="ri-admin-institution-name-input"
            />
            <S.ContactLink>
              <a
                style={{ textDecoration: "underline" }}
                href="/contact?message=Hello, I can't find my institution as an option. My institution is: "
                target="_blank"
              >
                Contact us
              </a>{" "}
              if you don&rsquo;t see your institution
            </S.ContactLink>
          </>
        )}
      </S.DropdownRow>
      {!universityHasCountry && prefillInstitutionName.length === 0 && (
        <S.DropdownRow>
          <S.DropdownTitle>Where is your institution located?</S.DropdownTitle>
          <SingleSelectDropdown
            placeholder="Search countries"
            value={countryOption}
            options={COUNTRY_OPTIONS}
            onChange={(option) =>
              setProfileInfo((oldProfileInfo) => ({
                ...oldProfileInfo,
                countryOption: option,
                country_code: getValueFromOption(option),
              }))
            }
            data-testid="ri-admin-institution-location-input"
          />
        </S.DropdownRow>
      )}
      <S.DropdownRow>
        <S.DropdownTitle>Which of the following best describes your role?</S.DropdownTitle>
        <SingleSelectDropdown
          placeholder="Select your role"
          options={userRoleOptions}
          onChange={(option) =>
            setProfileInfo((oldProfileInfo) => ({
              ...oldProfileInfo,
              role: getValueFromOption(option),
            }))
          }
          value={getOptionFromValue(role)}
          data-testid="ri-admin-role-input"
        />
      </S.DropdownRow>
      {FEATURE_FLAGS.UNIVERSITY_ADMIN_DISCIPLINES ? (
        <>
          <S.Hairline />
          <S.DropdownRow>
            <S.DropdownTitle>Which topics would you like to hear about on Halo?</S.DropdownTitle>
            <ChipsInput
              gap="6px"
              options={disciplineOptions ?? []}
              selectedValues={disciplines}
              onChange={handleDisciplines}
            />
          </S.DropdownRow>
        </>
      ) : (
        <S.DropdownRow>
          <S.DropdownTitle>
            Where do you focus your efforts on behalf of your faculty?
          </S.DropdownTitle>
          <MultiSelectDropdown
            placeholder="Select your focus"
            value={universityExpertise?.map(getOptionFromValue) ?? []}
            options={expertiseOptions}
            onChange={(options) =>
              setProfileInfo({
                ...profileInfo,
                universityExpertise: options.map(getValueFromOption),
              })
            }
            maxValues={3}
            data-testid="ri-admin-research-areas-input"
            isClearable={false}
          />
        </S.DropdownRow>
      )}
    </>
  );
}

export default ResearchInstitutionAdmin;
